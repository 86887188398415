import { navigate } from 'gatsby-link'
import React from 'react'
import Layout from '../components/layout'
let browserStorage = typeof localStorage === 'undefined' ? null : JSON.parse(localStorage.getItem('results'))

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      telephone: '',
      resultats: browserStorage,
      email: '',
      message: '',
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(() => navigate('/thanks/'))
      .catch((error) => alert(error))
    e.preventDefault()
  }
  handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

  render() {
    const { name, telephone, resultats, email, message } = this.state
    return (
      <Layout>
        <form
          className="bg-lilas m-4 p-2 h-full rounded-2xl max-w-xl mx-auto mt-12 lg:mt-24"
          name="contact"
          method="post"
          action="/thanks/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          <div id="border-wrapper" className="border-dashed border-2 border-white rounded-xl m-2 p-4">
            <h2 className="text-white font-header font-bold text-2xl text-center">Parlez-moi de vous</h2>
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" onChange={this.handleChange} />
              </label>
            </p>
            <p className="mt-4 text-center">
              <input
                type="text"
                name="name"
                placeholder="Mme Jeanne Dupont"
                onChange={this.handleChange}
                className="rounded-full w-5/6 h-10 outline-none focus:shadow-outline pl-5 bg-purple-200 focus:bg-white placeholder-gray-700 text-purple-800"
                value={name}
              />
            </p>
            <p className="mt-4 text-center">
              <input
                type="text"
                name="telephone"
                placeholder="06 12 12 12 12"
                onChange={this.handleChange}
                className="rounded-full w-5/6 h-10 outline-none focus:shadow-outline pl-5 bg-purple-200 focus:bg-white placeholder-gray-700 text-purple-800"
                value={telephone}
              />
            </p>
            <p className="mt-4 text-center">
              <input
                type="email"
                name="email"
                placeholder="jeanne.dupont@gmail.com"
                onChange={this.handleChange}
                className="rounded-full w-5/6 h-10 outline-none focus:shadow-outline pl-5 bg-purple-200 focus:bg-white placeholder-gray-700 text-purple-800"
                value={email}
              />
            </p>
            <p className="mt-4 text-center">
              <input
                type="text"
                name="resultats"
                className="potato rounded-full w-5/6 h-10 outline-none focus:shadow-outline pl-5 bg-purple-200 focus:bg-white placeholder-gray-700 text-purple-800"
                value={resultats}
                onChange={this.handleChange}
              />
            </p>
            <p className="mt-4 text-center">
              <textarea
                name="message"
                placeholder="Alors, voici le problème que je rencontre..."
                onChange={this.handleChange}
                className="h-24 w-5/6 rounded-xl outline-none focus:shadow-outline pl-5 pt-2 bg-purple-200 focus:bg-white placeholder-gray-700 text-purple-800"
                value={message}
              />
            </p>
            <p className="my-4 text-center">
              <button
                type="submit"
                className="bg-custom-purple text-white font-bold uppercase w-5/6 rounded-full h-10"
              >
                Envoyer
              </button>
            </p>
          </div>
        </form>
      </Layout>
    )
  }
}

export default ContactForm
